.socure-c-dv-fields-container {
  display: flex;
  width: 100%;
  font: 14px var(--socure-global-font-family);
}

.socure-c-dv-details-column {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 0 1em 1em 0;
}

.socure-c-dv-fields-column {
  display: flex;
  flex-direction: column;
  flex: 1 0 50%;
  justify-content: space-between;
}

.socure-c-dv-extracted-pii-fields-left {
  padding-right: 2em;
}

.socure-c-dv-extracted-pii-fields-right {
  padding: 0 1em;
}

.socure-c-dv-details-unavailable-text {
  font-size: 14px;
}

.socure-c-dv-fields-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 5px 0;
}

.socure-c-dv-field-label {
  flex: 1;
  font-weight: 600;
  text-transform: uppercase;
}

.socure-c-dv-field-value {
  flex: 1;
  text-align: right;
  font-weight: 400;
}

.socure-c-dv-field-error-text {
  font-weight: 600;
  font-style: italic;
}

.socure-c-dv-details-section-container {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto;
  background: transparent;
  border: 1px solid #c3c4c5;
  border-radius: 8px;
  padding: 1.2em;
  width: 100%;
  margin-bottom: 2em;
}

.socure-c-dv-details-section-header {
  text-transform: uppercase;
  margin-bottom: 0.5em;
  font: 700 18px /25px var(--socure-global-font-family);
}

.socure-c-dv-details-section-subheader {
  text-transform: uppercase;
  font: 700 14px /18px var(--socure-global-font-family);
  margin: 0.5em 0 1em 0;
}

.socure-c-dv-id-check-column {
  display: flex;
  flex-direction: column;
  flex: 1 0 50%;
  justify-content: space-evenly;
}

.socure-c-dv-id-check-row {
  display: flex;
  flex: 1;
  width: 95%;
  height: 100%;
}

.socure-c-dv-fields-grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 4em;
  width: 100%;
  font: 14px var(--socure-global-font-family);
  padding-right: 2em;
}

.socure-c-dv-fields-grid-column {
  display: grid;
  grid-template-rows: auto;
}

.socure-c-dv-fields-img-container {
  position: relative;
  padding-bottom: 125%; /* Adjusts the aspect ratio of contained image */
  overflow: hidden;
}

.socure-c-dv-fields-img-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.socure-c-dv-details-image-label {
  text-transform: uppercase;
  font: 700 12px /18px var(--socure-global-font-family);
  margin: 1em 0;
}

#socure-c-dv-details-ocr-vs-barcode-container {
  font-size: 14px;
}

#socure-c-dv-details-ocr-vs-barcode-table {
  table-layout: fixed;
}

#socure-c-dv-details-ocr-vs-barcode-header {
  height: 2em;
}

#socure-c-dv-details-ocr-vs-barcode-header-empty-column {
  width: 25%;
}

.socure-c-dv-details-ocr-vs-barcode-header-name {
  padding-left: 1em;
}

.socure-c-dv-details-ocr-vs-barcode-header-name-column-completion {
  font-weight: normal;
}

.socure-c-dv-details-ocr-vs-barcode-column {
  border-left: 1px solid #e3e3e3;
  padding-left: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  vertical-align: middle;
  height: 2.5em;
}

.socure-c-dv-details-ocr-address {
  background-color: #c3c4c5;
}

.socure-c-dv-details-ocr-vs-barcode-ocr-column {
  width: 37.5%;
}

.socure-c-dv-details-ocr-vs-barcode-column-mismatch {
  background-color: #fbf1f1;
  color: #d2362a;
}

.socure-c-dv-details-reference-transaction-container {
  font: 12px var(--socure-global-font-family);
  text-transform: uppercase;
  font-weight: 700;
  display: flex;
  background: #f5f5f5;
  padding: 10px 15px 10px 10px;
  border-radius: 5px;
}

.socure-c-dv-details-reference-transaction-link {
  color: var(--socure-global--color-transaction-color);
  cursor: pointer;
}
