/*
Copied from Transactions.scss for standalone app
Start
*/

.socure-c-td-results-container {
  display: flex;
  flex: 1;
  justify-content: space-between;
  overflow: hidden;
  position: relative;

  &.socure-c-td-results-scroll {
    @media (max-width: 900px) {
      overflow-y: auto;
    }
  }
}

.socure-c-td-info-display {
  background-color: var(--socure-global--color-white);
  overflow-y: auto;
}

.scoure-c-td-info-container {
  flex: 0 0 35%;

  @media (max-width: 900px) {
    bottom: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 48px;
    z-index: 2;
  }
}

.socure-c-td-info-show {
  width: auto;

  @media (max-width: 600px) {
    width: 100%;
  }
}

.socure-c-td-button-container {
  position: relative;

  &.socure-c-td-button-container-open {
    @media (max-width: 600px) {
      position: absolute;
      right: 0;
    }
  }
}

.socure-c-td-transactions-container {
  height: 820px;
  margin-left: -8px;

  @media (max-width: 1320px) {
    width: calc(100vw - 200px);
  }

  @media (min-width: 1320px) {
    width: 1120px;
  }
}

.socure-c-td-info-button {
  align-items: center;
  background-color: var(--socure-global--color-orange-60);
  border-radius: 8px 0 0 8px;
  box-shadow: 0 4px 16px #1d2a441a;
  cursor: pointer;
  display: none;
  justify-content: center;
  margin-top: 16px;
  width: 48px;

  @media (max-width: 900px) {
    display: flex;
  }
}

.socure-c-ct-fields-header {
  color: #22272f;
  font: normal normal normal 20px/22px Open Sans;
}

.socure-c-wt-container {
  .socure-c-wt-buttons {
    margin-top: 12px;
  }

  .socure-c-wt-tier-details-container {
    border-bottom: 1px solid #e3e4e6;
    margin-bottom: 22px;
    padding-bottom: 20px;
    padding-top: 20px;

    .socure-c-wt-field-value {
      color: #22272f;
      font: normal normal normal 14px/14px Open Sans;
      letter-spacing: 0;
    }

    .socure-c-wt-field-label {
      color: #22272f;
      font: normal normal bold 14px/14px Open Sans;
      margin-bottom: 12px;
    }
  }
}

.socure-c-ct-workspace-container {
  .socure-c-file-upload-outer-container {
    width: 320px;
  }

  .socure-c-ct-doc-type-container {
    margin-top: 12px;
  }
}

.socure-c-wt-override-label {
  color: #22272f;
  font: normal normal normal 20px/22px Open Sans;
  margin-bottom: 13px;
  margin-top: 20px;
}

.socure-c-wt-override-secondary-label {
  color: #22272f;
  font: normal normal bold 14px/22px Open Sans;
  margin-bottom: 12px;
  margin-top: 24px;
}

.socure-c-wt-override-secondary-label-2 {
  color: #22272f;
  font: normal normal bold 14px/22px Open Sans;
  margin-bottom: 2px;
  margin-top: 24px;
}

.socure-c-document-request-input-field-1 {
  margin-bottom: 20px;
}

.socure-c-document-request-input-field-2 {
  margin-bottom: 28px;
}

/*
Copied from Transactions.scss for standalone app
End
*/

/*
Copied from styles.scss for standalone app
Start
*/

.socure-c-toast-container {
  .Toastify__toast {
    //stylelint-disable-line
    padding: 0 !important;
  }
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.input-icon-start {
  left: 18px;
  top: 20px;
}

.input-icon-end {
  right: 18px;
  top: 20px;
}

.p-auto {
  padding: auto;
}

.m-auto {
  margin: auto;
}

.flex {
  display: flex !important;
}

.flex1-1 {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.justify-left {
  justify-content: left;
}

.justify-center {
  justify-content: center !important;
}

.justify-right {
  justify-content: right;
}

.justify-end {
  justify-content: flex-end;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-space-around {
  justify-content: space-around;
}

.align-left {
  align-items: flex-end;
}

.align-center {
  align-items: center;
}

.align-right {
  align-items: flex-end;
}

.mt-1 {
  margin-top: 16px !important;
}

.mr-1 {
  margin-right: 16px !important;
}

.ml-1 {
  margin-left: 16px !important;
}

.mb-3\/2 {
  margin-bottom: 24px !important;
}

.mt-3\/2 {
  margin-top: 24px !important;
}

.mr-3\/2 {
  margin-right: 24px !important;
}

.mt-2 {
  margin-top: 32px !important;
}

.ma-1 {
  margin: 16px !important;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.cursor-pointer {
  cursor: pointer;
}

/*
Copied from styles.scss for standalone app
End
*/

.docv-mb-1 {
  margin-bottom: 1rem;
}

.docv-mb-2 {
  margin-bottom: 2rem;
}

.m-15-0 {
  margin: 1.5rem 0;
}

.fl-right {
  float: right;
}

.termination-confirmation-text {
  margin-top: 16px;
  width: 400px;
}

.filters-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.filter-dropdown {
  width: 48%;
}

.border-bottom-2 {
  border-bottom: 2px solid var(--socure-global--color-popver-footer);
}

.combined-input-first {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.combined-input-second {
  border-top: none !important;
  margin-top: -1px !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.country-input-label {
  font-size: var(--socure-global--FontSize--2xs);
  font-weight: var(--socure-global--FontWeight--bold);
  line-height: var(--socure-global--LineHeight-24);
  color: var(--socure-global--color-text-input-label);
  text-transform: uppercase;
}

.country-input {
  border: none;
  background-color: rgba(0, 0, 0, 0.05);
  position: relative;
  display: inline-block;
  width: 100%;
  height: 30px;
  border-radius: 4px;
  padding: 12px;
}

.sub-header {
  font-size: 14px;
}

@media screen and (min-width: 1020px) {
  .customer-info-container {
    display: flex;
    justify-content: space-between;
    width: 850px;
    gap: 50px;
  }
}

@media screen and (max-width: 1019px) {
  .customer-info-container {
    display: flex;
    flex-direction: column;
    width: auto;
  }
}