.socure-c-td-results-container {
  display: flex;
  flex: 1;
  justify-content: space-between;
  overflow: hidden;
  position: relative;

  &.socure-c-td-results-scroll {
    @media (max-width: 900px) {
      overflow-y: auto;
    }
  }
}

.socure-c-td-info-display {
  background-color: var(--socure-global--color-white);
  margin-right: 40px;
  overflow-y: auto;
  width: 372px;
}

.scoure-c-td-info-container {
  flex: 0 0 35%;

  @media (max-width: 900px) {
    bottom: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 48px;
    z-index: 2;
  }
}

.socure-c-td-info-show {
  width: auto;

  @media (max-width: 600px) {
    width: 100%;
  }
}

.socure-c-td-button-container {
  position: relative;

  &.socure-c-td-button-container-open {
    @media (max-width: 600px) {
      position: absolute;
      right: 0;
    }
  }
}

.socure-c-td-transactions-container {
  box-shadow: inset 0 16px 20px -20px #0000001a;
  height: 820px;
  position: relative;

  @media (max-width: 1320px) {
    width: calc(100vw - 200px);
  }

  @media (min-width: 1320px) {
    width: 1120px;
  }
}

.socure-c-td-info-button {
  align-items: center;
  background-color: var(--socure-global--color-orange-60);
  border-radius: 8px 0 0 8px;
  box-shadow: 0 4px 16px #1d2a441a;
  cursor: pointer;
  display: none;
  height: 48px;
  justify-content: center;
  margin-top: 16px;
  width: 48px;

  @media (max-width: 900px) {
    display: flex;
  }
}

.socure-c-ct-fields-header {
  color: #22272f;
  font: normal normal normal 20px/22px Open Sans;
}

.socure-c-wt-container {
  .socure-c-wt-buttons {
    margin-top: 12px;
  }

  .socure-c-wt-tier-details-container {
    border-bottom: 1px solid #e3e4e6;
    margin-bottom: 22px;
    padding-bottom: 20px;
    padding-top: 20px;

    .socure-c-wt-field-value {
      color: #22272f;
      font: normal normal normal 14px/14px Open Sans;
      letter-spacing: 0;
    }

    .socure-c-wt-field-label {
      color: #22272f;
      font: normal normal bold 14px/14px Open Sans;
      margin-bottom: 12px;
    }
  }
}

.socure-c-ct-workspace-container {
  .socure-c-file-upload-outer-container {
    width: 320px;
  }

  .socure-c-ct-doc-type-container {
    margin-top: 12px;
  }
}

.socure-c-wt-override-label {
  color: #22272f;
  font: normal normal normal 20px/22px Open Sans;
  margin-bottom: 13px;
  margin-top: 20px;
}

.socure-c-wt-override-secondary-label {
  color: #22272f;
  font: normal normal bold 14px/22px Open Sans;
  margin-bottom: 12px;
  margin-top: 24px;
}

.socure-c-wt-override-secondary-label-2 {
  color: #22272f;
  font: normal normal bold 14px/22px Open Sans;
  margin-bottom: 2px;
  margin-top: 24px;
}

.socure-c-document-request-input-field-1 {
  margin-bottom: 20px;
}

.socure-c-document-request-input-field-2 {
  margin-bottom: 28px;
}


// added for pdf download and to be removed in future
#socure-c-printContents {
  .socure-c-td-transactions-container, .scoure-c-td-info-container, .scoure-c-td-info-container + div, .socure-c-td-info-display  {
    width: 100% !important;
    display: block;
    position: initial;
  }
  .socure-c-modal-popup__message-container {
    display: initial !important;
    overflow-x: initial !important;
    overflow-y: initial !important;
  }
  .scoure-c-td-info-container {
    margin-bottom: 40px;
  }
  .cursor-pointer {
    display: none;
  }
  .socure-c-td-transactions-container {
    width: 100% !important;
    height: 100% !important;
  }
  .socure-c-td-results-container {
    padding: 10px 0 0 25px;
    background: #fff;
  }
  .socure-c-content-inner-container > div:not(.expend-collaps) {
    border: 1px solid #ccc;
    padding-right: 0;
  }
  .socure-c-collapsible-header {
    border-bottom: 1px solid #ccc;
  }
  .socure-c-td-results-container * {
    box-shadow: none;
  }
  .transaction-popup-content-override {
    padding: 10px 20px !important;
  }
  div.socure-c-collapsible-header > div:first-child {
    display: none !important;
  }
  #jsonTextarea {
    width: 700px;
    line-height: 16px;
    white-space: pre-wrap;
    letter-spacing: 0.02em;
    padding: 20px;
  }
  @media print
  {
    .page-break  { 
      display:block; 
      page-break-before:always; 
    }

  }
  h1 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 30px;
    padding: 20px;
    border-bottom: 1px solid #ccc;
  }
}


// For print overlay while exporting pdf
div#socure-c-print-overlay {
  z-index: 10000;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #000;
  opacity: .8;

  span {
    color: #fff;
    font-size: 22px;
    text-align: center;
    display: block;
    margin: 50px;
  }
}