.subscription-collapse-container {
  margin-top: 15px;
}

.subscription-collapse-container span {
  font-size: 12px;
  color: var(--socure-global--color-teal-button) !important;
  text-transform: uppercase;
}

