.socure-websdk-header-label {
  align-items: center;
  color: var(--socure-global--color-black);
  display: flex;
  font-size: 20px;
  line-height: 28px;
  margin: 18px 16px 18px 24px;
}

.socure-websdk-container {
  height: 416px;
  width: 625px;
}

.socure-websdk-heading {
  font-weight: bold;
  padding: 24px 0 16px;
  text-align: center;
}

.socure-websdk-subheading {
  padding: 0 16px 16px;
  text-align: center;
}

.socure-websdk-description {
  padding: 0 16px;
  text-align: center;
}

.socure-websdk-button {
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  padding: 32px 0 34px;
  padding: 0.8em 3em;
  text-align: center;
  width: 250px;
}

.socure-websdk-image {
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  padding: 32px 0 34px;
  padding: 0.8em 3em;
  text-align: center;
  width: 250px;
}

.socure-websdk-footer {
  bottom: -24px;
  color: var(--socure-global--color-blue-20);
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  left: 38%;
  line-height: 16px;
  position: absolute;
}

.socure-websdk-v4-footer {
  color: var(--socure-global--color-blue-20);
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 16px;
  position: absolute;
  bottom: 10px;
  width: 100%;
  justify-content: center;
  text-align: center;
}

.socure-websdk-divided-container {
  width: 50%;
  text-align: center;
  margin: 15px 30px;
}

.socure-websdk-divider {
  display: inline-flex;
  flex-direction: column;
  margin: 20px 0px;
}

.socure-websdk-divider .liner {
  align-self: center;
  border: 1px solid #c3c4c5;
  flex: 1 1 auto;
}

.socure-websdk-options-container {
  display: flex;
  justify-content: center;
  text-align: center;
}

.socure-websdk-page-container {
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  height: 100%;
  justify-content: center;
}

.option-divider-container {
  display: inline-flex;
  flex-direction: column;
  margin: 32px 0;
}

.option-divider-container .liner {
  align-self: center;
  border: 1px solid #c3c4c5;
  flex: 1 1 auto;
}

.option-container {
  margin: 32px 32px 34px;
  max-width: 400px;
  text-align: center;
}

input {
  border-radius: 5px;
  height: 35px;
  width: 100%;
}

.tab-content {
  cursor: pointer;
  padding: 10px 5px;
}

.tab-content:hover,
.tab-content:focus,
.tab-content:active {
  background: #f78a27;
  color: var(--socure-global--color-white);
  outline: none;
}

.title {
  cursor: pointer;
  font-weight: 700;
}

.description {
  cursor: pointer;
}

.error {
  color: var(--socure-global--color-red-40);
  font-size: var(--socure-global--FontSize--xs);
  font-style: normal;
  font-weight: normal;
  line-height: var(--socure-global--FontSize--m);
  text-align: left;
}

.socure-websdk-browser-topbar-left {
  background-image: url('./assets/topbar_left.png');
  background-repeat: no-repeat;
  flex: 0 0 99px;
  height: 40px;
}

.socure-websdk-browser-topbar-right {
  background-image: url('./assets/topbar_right.png');
  background-repeat: no-repeat;
  flex: 0 0 33px;
  height: 40px;
}

.socure-websdk-browser-topbar-center {
  background-image: url('./assets/topbar_center.png');
  background-repeat: repeat-x;
  flex: 1 1 auto;
  height: 40px;
}
