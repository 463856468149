.socure-c-reports-filterby-label {
  font: normal normal bold 14px/22px Open Sans;
  color: var(--socure-global--color-text-input-border-2);
  opacity: 1;
  margin-bottom: 16px;
}

.socure-c-reports-date-label {
  text-align: left;
  font: normal normal bold 12px/32px Open Sans;
  letter-spacing: 0px;
  color: var(--socure-global--cb-gray-form-label);
  text-transform: uppercase;
  opacity: 1;
}

.socure-c-reports-form-label {
  text-align: left;
  font: normal normal bold 12px/16px Open Sans;
  letter-spacing: 0px;
  color: var(--socure-global--cb-gray-form-label);
  text-transform: uppercase;
  opacity: 1;
}

.socure-c-reports-advanced-filter {
  margin-top: 16px;
}

.socure-c-reports-advanced-filter button {
  color: #008CD6;
  font: normal normal 600 14px/32px Open Sans;
  padding: 0px;
}

.socure-c-reports-columns-container {
  width: 324px;
}

.socure-c-reports-properties-container {
  width: 280px;
}

.socure-c-reports-properties-container div:has(.account_ids-selector) {
  margin-top: 1rem;
}

.socure-c-report-download-modal-container {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 8px rgba(29,42,68,0.1);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

.socure-c-report-download-modal_header-text {
  color: var(--socure-global--color-white);
}

