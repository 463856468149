
.socure-neg-pos-list {
  padding: 0 !important;
  height: 100%;
  .socure-neg-pos-list-link {
    font-size: 14px;
    font-weight: 700;
    color: #2b96b7;
  }
  .socure-neg-pos-list-button {
    padding: 1rem;
  }
  .socure-neg-pos-upload-section {
    flex-basis: 75%;
    padding-left: 24px;
    padding-top: 18px;
    overflow-y: auto;
    padding-bottom: 18px;
    .socure-c-file-upload-inner-container {
      border-radius: 4px 0px 0px 0px;
      height: 191px;
      border: 1px dashed var(--Grey-Palette-Grey30, #B2B2B2);
      background: #FAFAFA;
    }
    .socure-c-file-upload-outer-container {
      width: calc(min(522px, 60%));
    }
    .socure-c-file-upload-label-text-below{
      display: none;
    }
  }
  .socure-neg-pos-upload-progress-icon{
    height: 86px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--Grey-Palette-Grey-20, #E1E0DF);
  width: calc(min(86px,20%));
  }
  .socure-neg-pos-audit-trail-section {
    flex-basis: 25%;
    border: 1px solid #e3e4e6;
    display: flex;
    .audit-trail-title-icon {
      svg {
        vertical-align: middle;
      }
    }
    .audit-trail-title {
      margin-left: 4px;
    }
  }
}
