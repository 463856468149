.socure-c-timeline {
  margin-left: 1px;
}

.socure-c-timeline-item {
  margin-bottom: 0;
}

.socure-c-timeline-item__container {
  &:hover button {
    display: inline-flex;
  }
}

.avatar_background {
  background-color: #454545 !important;
  color: #fff !important;
  font-weight: 600 !important;
}

.pad-r-3 {
  padding-right: 3px;
}
