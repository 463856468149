$breakpoint-tablet: 900px;
$breakpoint-mobile: 600px;

.socure-c-wle-details-container {
  background-color: var(--socure-global--color-white);
  display: flex;
  justify-content: space-between;
  overflow: hidden;

  @media (max-width: $breakpoint-tablet) {
    flex-wrap: wrap;
    overflow-y: auto;
  }
}

.socure-c-wle-nav-container {
  display: flex;
}

.socure-c-queried-data-container {
  padding: 24px;
}

.socure-c-wle-entity-view-container {
  overflow-y: auto;
  padding: 36px 24px 41px 84px;
  width: 75%;

  @media (max-width: $breakpoint-tablet) {
    padding: 24px 12px;
    width: 100%;
  }

  @media (max-width: $breakpoint-mobile) {
    padding: 12px 8px;
    width: 100%;
  }
}

.socure-c-wle-transaction-detail-container {
  background-color: var(--socure-global--color-white);
  overflow-y: auto;
  width: 25%;

  @media (max-width: $breakpoint-tablet) {
    width: 100%;
  }
}

.socure-c-wle-each-row {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;
}

.socure-c-wle-each-block {
  padding-right: 44px;
}

.scoure-c-wle-transaction-data-label {
  color: var(--socure-global--color-black);
  font: var(--socure-global--FontWeight--semi-bold) var(--socure-global--FontSize--xs)
    var(--socure-global-font-family);
  letter-spacing: 0;
  margin-right: 32px;
  opacity: 1;
  text-align: left;
  text-transform: uppercase;
  white-space: nowrap;
}

.scoure-c-wle-transaction-data-subheading-label {
  color: #999;
  font: var(--socure-global--FontWeight--semi-bold) var(--socure-global--FontSize--2xs)
    var(--socure-global-font-family);
  letter-spacing: 0;
  opacity: 1;
  text-align: left;
  text-transform: uppercase;
}

.socure-c-wle-query-data-value {
  color: var(--socure-global--color-black);
  font: var(--socure-global--FontWeight--normal) var(--socure-global--FontSize--xs)
    var(--socure-global-font-family);
  font-style: normal;
  letter-spacing: 0;
  opacity: 1;
  word-break: break-all;
}

.socure-c-wle-query-separator {
  border: 0.5px solid #ccc;
  margin: 4px 0 12px;
  opacity: 1;
  width: 100%;
}

.socure-c-wle-query-separator-top {
  border: 0.5px solid #999;
  margin: 12px 0 16px;
  opacity: 1;
  width: 100%;
}

.socure-c-wle-emptylist-container {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
}

.socure-c-wle-query-data-heading-lable {
  color: var(--socure-global--color-popver-heading);
  font: var(--socure-global--FontWeight--bold) var(--socure-global--FontSize--18) /
    var(--socure-global--spacer--m) var(--socure-global-font-family);
  letter-spacing: 0;
  line-height: var(--socure-global--LineHeight-28);
  opacity: 1;
  text-align: left;
}

.scoure-c-wle-transaction-data-heading {
  background-color: transparent;
  border: false;
  color: var(--socure-global--color-black);
  display: flex;
  font: var(--socure-global--FontWeight--bold) var(--socure-global--FontSize--xs) /
    var(--socure-global--spacer--m) var(--socure-global-font-family);
  font-style: normal;
  justify-content: space-between;
  letter-spacing: 0;
  opacity: 1;
  outline: none;
  padding-bottom: 10px;
  text-align: left;
  text-transform: uppercase;
  width: 100%;
}

.socure-c-wle-device-each-row {
  align-items: center;
  display: flex;
  padding-bottom: 16px;
}

.scoure-c-wle-device-data-label {
  color: var(--socure-global--color-text-input-eye-hover);
  font-size: 12px;
  font-weight: bold;
  opacity: 0.75;
  text-align: left;
  text-transform: uppercase;
  width: 213px;
}

.scoure-c-wle-device-data-label-sub-heading {
  color: var(--socure-global--color-black);
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 13px/14px
    var(--unnamed-font-family-open-sans);
  letter-spacing: 0;
  margin: 8px 0;
  opacity: 1;
  text-align: left;
  text-transform: uppercase;
  width: 213px;
}

.transaction-popup-content-override {
  padding: 0 16px 0 32px !important;
}

.socure-c-td-transactions-container .socure-c-tabs-outer-container {
  margin-right: 16px;
}

#exportBtn .socure-c-menuItem__List {
  width: auto;
}
