

.neg-pos-tab {
  width: 100%;
  .socure-c-textInput-InnerContainer {
    max-width: fit-content;
  }
  .neg-pos-notes-input {
    height: 64px;
    width: 244px;
  }
  .neg-pos-dropdown {
    width: max-content;
    min-width: 6rem;
  }
  .socure-c-tabs-outer-container {
    padding-left: 20%;
    padding-top: 2rem;
    font-size: 14px;
    font-weight: 700;
  }
  .socure-c-content-outer-container {
    border: none !important;
    border-top: 1px solid var(--socure-global--color-gray-80) !important;
  }
}
