.socure-c-simulate-date-container {
    display: flex;
    align-items: center;
    margin: 0 25px;
    padding: 10px 0 20px 0;
    border-bottom: 1px solid #B2B2B2;

    .socure-c-simulate-date-selector {
        width: 150px;
        display: inline-block;
        position: relative;
        top: 7px;

        #dateSelector {
            height: 32px;
        }
    }

    > div {
        .socure-c-date-selector-label {
            font-size: var(--socure-global--FontSize--xs);
            line-height: var(--socure-global--LineHeight-24);
            color: var(--socure-global--color-gray-20);
            position: relative;
            top: -3px;

            + div {
                margin-top: 0;
                height: c;
            }
        }

        .socure-c-textInput-OutterContainer {
            display: block;

            > div {
                top: 2px;
            }

            input {
                margin: 0;
                height: 32px;
            }
        }
    }

    #socure-c-simulate-run-btn {
        margin-left: 25px;
        border-radius: 4px;
        margin-top: 28px;
        height: 32px;
    }

    > div {
        margin-right: 25px;

        &.socure-c-simulate-comment, &.socure-c-simulate-name, &.socure-c-liveLogic-dropdown {
            width: 300px;

            > div {
                margin: 0;
                height: 32px;

                button {
                    height: 32px;
                }
            }
        }
    }
}

.socure-c-simulation-result-container {
    margin: 0 25px;
    .socure-c-simulation-result-header {
        padding: 15px 0;

        .socure-c-simulation-result-action {
            position: relative;
            top: -2px;
            float: right;

            button {
                margin-left: 16px;
            }
        }

        span {
            font-size: 20px;
            line-height: 28px;
            color: #2B3139;
        }
    }
    .socure-c-simulation-result-body {
        border-radius: 6px;
        padding: 15px;
        display: flex;
        flex-wrap: wrap;
        position: relative;

        .socure-c-simulation-result-table {
            transition: .1s linear;
            width: 100%;

            .socure-c-simulation-result-table-header {
                margin-bottom: 16px;
                &:after {
                    content: "";
                    display: block;
                    clear: both;
                }
                .socure-c-simulation-result-optoin {
                    float: right;
                    width: 325px;
    
                    > div {
                        margin-top: 0;
                        height: 32px;
    
                        > button {
                            height: 32px;

                            .socure-c-dropdown__placeholder-label--empty {
                                top: 4px;
                            }
                        }
                    }
                }
                #socure-c-simulation-result-view-all {
                    float: right;
                    background: var(--socure-global--color-white);
                    color: var(--socure-global--color-decision-condition-button);
                    cursor: pointer;
                }
            }
            .socure-c-simulation-name-status {
                color: var(--socure-global--color-red-60);
                margin-left: 30px;
            }
        }
        .socure-c-selected-simulation {
            width: 45%;
            margin-left: 32px;
            padding: 0 16px 0 25px;
            border-left: 1px solid #E8E8E8;
            position: absolute;
            right: 0px;
            top: 0;
            background: #fff;

            > div, > ul {
                padding: 12px 0;
                border-bottom: 1px solid #E1E0DF;

                .socure-c-simulation-name-field {
                    margin-bottom: 12px;
                }
                svg {
                    position: relative;
                    top: 4px;
                }
            }

            .socure-c-selected-simulation-name {
                > svg {
                    position: relative;
                    top: 4px;
                    left: -2px;
                }

                span.socure-c-simulation-name {
                    font-weight: 600;
                    font-size: 14px;
                    text-transform: uppercase;
                    margin: 0 16px;
                    color: #858585;
                }

                .socure-c-simulation-comment {
                    margin: 0 16px;
                    font-weight: 400;
                    font-size: 12px;
                    color: #26272C;
                }

                span.socure-c-simulation-date {
                    font-weight: 400;
                    font-size: 14px;
                    color: #858585;
                }
            }

            ul.socure-c-selected-simulation-details {
                display: flex;
                justify-content: space-between;

                li {
                    text-align: center;
                }

                label {
                    display: block;
                    font-weight: 700;
                    font-size: 10px;
                    line-height: 19px;
                    text-transform: uppercase;
                    color: #858585;
                }

                span {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 19px;
                    color: #26272C;
                }
            }
            .socure-c-simulation-text {
                &::after {
                    display: block;
                    content: '';
                    clear: both;
                }

                > div {
                    float: left;
                    width: 460px;

                    span {
                        color: #858585;
                        margin-bottom: 4px;
                        display: inline-block;
                        font-weight: 700;
                        font-size: 12px;
                    }
                    p {
                        color: #54565A;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 22px;
                    }
                }
            }
            .socure-c-simulation-table {
                .header-cell {
                    font-weight: 700;
                    font-size: 10px;
                    color: #858585;
                    text-align: center;
                }
                .socure-c-datagrid-row {
                    .row-entry {
                        height: 22px;
                        border-bottom: 1px solid #F0EFEF;
    
                        .data-cell {
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 18px;
                            color: #26272C;
                        }
                    }
                    &:last-child .row-entry {
                        border: none;
                    }
                }
                .socure-c-datagrid-container > div:first-child {
                    height: 22px;
                    min-height: 22px;
                }
            }
            .socure-c-simulation-text.socure-c-simulation-acceptance-rate {
                position: relative;
                
                .socure-c-guageChart-container {
                    position: absolute;
                    top: -12px;
                    right: -40px;
                    canvas {
                        width: 90px !important;
                        height: 90px !important;
                    }
                    .socure-c-guageChart-details {
                        h3 {
                            display: inline-block;
                            position: relative;
                            right: 28px;
                            top: 7px;
                        }
                    }
                }
            }
            
            
        }
        &.socure-c-simulation-data-visible .socure-c-simulation-result-table {
            width: 55%;
            padding-right: 15px;
        }
        .socure-c-datagrid-container {
            .data-cell, .header-cell {
                text-align: center;

                &:first-child {
                    text-align: left;
                }
            }
        }
    }
    .socure-c-simulation-result-table-body {
        .data-cell {
            .socure-c-text-overflow {
                font-weight: 400;
                font-size: 14px;
                color: #1E2833;
            }

            &:first-child .socure-c-text-overflow {
                font-weight: 700;
                font-size: 13px;
                color: #0E1115;
            }
        }
    }
    .simulation-status-loader {
        position: relative;

        > div {
            display: inline-block;
            margin-left: 15px;
        }
    }
    .socure-c-transaction-alert-tooltip {
        margin-left: 25px;
        display: inline-block;

        > div {
            width: 400px !important;
            padding: 4px 8px;
            background: #5B5F66;
        }
        .socure-c-transaction-alert-tooltip-content {
            border-radius: 4px;
            font-size: 13px;
        }
    }
}

.socure-c-modal-popup__container {
    .socure-c-deploy-container, .socure-c-revert-container, .socure-c-event-mapping-container {
        > div:first-child {
            margin: 18px 0 5px;
            font-weight: 400;
            font-size: 18px;
            line-height: 25px;
            color: #1E2833;
            display: none;
        }
        // .socure-c-textArea-OutterContainer {
        //     textarea {
        //         margin: 0 0 20px;
        //     }
        // }
        > label {
            font-weight: 700;
            font-size: 12px;
            display: inline-block;
            text-transform: uppercase;
            color: #858585;
            margin-bottom: 5px;

            &.socure-c-deploy-comment-label {
                margin-top: 24px;
            }
            &.socure-c-deploy-reason-label {
                position: relative;
                bottom: -10px;
            }
            &.socure-c-select-environment-label {
                margin-bottom: 10px;
            }
        }
        .socure-c-checkbox-container {
            margin-top: 16px;

            .socure-c-checkbox-label {
                text-transform: capitalize;
            }
        }
        .socure-c-deploy-event-dropdown {
            display: flex;
            flex-wrap: nowrap;
            margin-bottom: 16px;

            > div {
                width: 250px;
                margin-right: 30px;
                display: inline-block;
            }
            > span {
                display: inline-block;
                cursor: pointer;
                position: relative;
                top: 18px;
                width: 32px;
                height: 32px;
                text-align: center;
                padding: 7px;
                background: #26272C;
                border-radius: 4px;

                &.event-add-disable {
                    cursor: not-allowed;
                } 
            }
            span {
                text-transform: capitalize;
            }
        }
        .socure-c-event-tags-container {
            display: flex;
            margin: 16px 0 0 0;
            flex-wrap: wrap;
            max-height: 130px;
            overflow: auto;

            .socure-c-event-tags {
                display: flex;
                margin: 0 15px 15px 0;
                padding: 8px 12px;
                align-items: center;
                gap: 8px;
                border-radius: 50px;
                border: 1px solid var(--Grey-Palette-Grey-20, #E1E0DF);
                background: var(--Grey-Palette-Grey-20, #E1E0DF);

                span {
                    color: var(--Grayscale-Gray-100, #161616);
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 16px;
                    letter-spacing: 0.4px;
                    text-transform: capitalize;
                }
                cite {
                    text-transform: capitalize;
                }
                .socure-c-event-tag-close {
                    cursor: pointer;
                    position: relative;
                    top: 3px;
                }
                .socure-c-event-mapping-tooltip-content {
                    border-radius: 4px;
                    font-size: 12px;
                    color: #fff;
                }
                &.socure-c-event-exist {
                    border-color: red;

                    > div {
                        width: 150px !important;
                        padding: 4px 8px;
                        background: #5B5F66;
                    }
                    > span {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                    }
                }
            }
        }
        .socure-c-textArea-OutterContainer {
            margin-bottom: 20px;
        }
    }

    .socure-c-revert-container, .socure-c-event-update-container {
        > div:first-child {
            margin: 0 0 10px;
            font-weight: 400;
            font-size: 16px;
            line-height: 25px;
        }
        textarea {
            width: 420px;
        }
        .socure-c-previous-live-details {
            label {
                font-weight: 600;
                font-size: 14px;
            }
            span {
                font-weight: 400;
                font-size: 14px;
                margin-right: 30px;
            }
        }
        .socure-c-textArea {
            width: 100%;
        }
        .socure-c-deploy-event-dropdown {
            margin-bottom: 0;
        }
    }
}
#decision-clone {
    visibility: hidden;
}
.socure-c-group-condition-container {
    width: 100%;
}
.socure-c-modal-popup__title-container .socure-c-decision-old-logic > div:nth-child(2n) {
    pointer-events: none;
    cursor: not-allowed !important;
}

.socure-c-lb-sub-group .socure-c-lb-condition-row .socure-c-menuItem__List #moveTo {
    display: none;
}

.socure-c-saveLogic-container {
    position: relative;

    span.socure-c-saveLogic-asteric {
        position: absolute;
        top: 31px;
        left: 76px;
        color: var(--socure-global--color-text-input-error);
        font-size: var(--socure-global--FontSize--2xs);
    }
}


//  styles for decision popup

.socure-c-deploy-container {
    padding: 16px 0;
    display: flex;
    width: 600px;

    .socure-c-deploy-tab-content {
        width: 100%;
    }
    
}
.socure-c-deploy-tab {
    width: 170px;
    border-right: 1px solid #CCCCCC;
    margin-right: 25px !important;
    margin-top: 0 !important;
    padding-top: 18px;

    ol {
        list-style-type: none;
        padding-left: 25px;
        border-left: 1px solid #DDD;

        li {
            margin-bottom: 33px;
            position: relative;
            counter-increment: step-counter;
            font-size: 15px;
            cursor: pointer;

            &:before {
                color: #fff;
                content: counter(step-counter);
                position: absolute;
                right: 113%;
                top: 50%;
                transform: translateY(-50%);
                width: 24px;
                height: 24px;
                font-size: 12px;
                font-weight: 600;
                border-radius: 100%;
                background: #00849E;
                text-align: center;
                line-height: 24px;
            }
        }
    }
}

.socure-c-deploy-action {
    width: 100%;

    label {
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        background: transparent;
    }
    > div {
        margin: 0 0 16px 0;
    }
}


// For decision request container

.socure-c-decision-request-container {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 10;
    width: 450px;
    height: 100%;
    background: #FFF;
    box-shadow: -7px 0px 24px 0px rgba(14, 17, 21, 0.10);
	padding-bottom: 70px;
    border-radius: 0 8px 8px 0;

    .socure-c-decision-request-header {
        padding: 14px 12px;
        border-bottom: 1px solid #E3E4E6;

        span {
            font-size: 22px;
            font-weight: 600;
            line-height: 24px;
            color: var(--Header-Text, #1E2833);
            padding-right: 10px;
        }
        .socure-c-request-header-action {
            float: right;

            > div {
                display: inline-block;
            }

            svg {
                margin-right: 10px;
                cursor: pointer;
            }
        }
    }
    .socure-c-decision-request-body .socure-c-request-sub-accounts {
        padding: 20px;

        > div {
            margin-bottom: 15px;

            label {
                color: var(--grey-palette-grey-90, #26272C);
                font-size: 14px;
                font-weight: 700;
                line-height: 22px;
                display: block;
            } 
            span {
                color: var(--grey-palette-grey-90, #26272C);
                font-size: 14px;
                font-weight: 500;
                line-height: 22px;
                display: inline-block;
            }
        }
    }
    .socure-c-decision-request-body {
		height: calc(100% - 70px);
    	overflow: auto;

		.socure-c-request-outcome-changes {
			padding: 12px 8px;
	
			
		}
	}
    .socure-c-request-footer-action {
        position: absolute;
        width: 100%;
        bottom: 0;
        padding: 10px 16px;
        border-top: 1px solid #E3E4E6;
		background-color: #fff;
        border-radius: 0 8px 8px 0;

        .socure-c-request-footer-submit {
            display: flex;
            justify-content: flex-end;
            > button {
                margin-left: 15px;
            }
        }
    }
    .socure-c-request-submit-container {
        padding: 16px 0;

        p {
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            color: var(--grey-palette-black, #000);

            span {
                font-weight: 700;
            }
        }
        label {
            font-size: 12px;
            font-weight: 600;
            text-transform: uppercase;
            margin-top: 15px;
            display: inline-block;
            color: #858585;
        }
        textarea {
            margin-top: 0;
            height: 70px;
        }
    }
    .socure-c-modal-popup__container {
        min-width: 440px;
    }
}

.socure-c-sponsorbank-feedback-container {
    position: relative;
    cursor: pointer;
    top: 20px;

    .socure-c-sponsorbank-feedback-result {
        position: absolute;
        top: 100%;
        width: 470px;
        padding: 20px 0 30px 16px;
        border-radius: 5px;
        z-index: 12;
        border: 1px solid #CDCDCD;
        background: #FFF;
        box-shadow: 0px 12px 12px 0px rgba(0,0,0,0.25);
    
        p {
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;

            span {
                font-weight: 700;
            }
        }
    
        .socure-c-request-outcome-collapse-container {
            padding: 12px 16px 0 0;
            max-height: 310px;
            overflow: auto;
    
            .socure-c-request-status-count {
                margin-left: 60px;
            }
            .socure-c-request-condition-action {
                cursor: default !important;
            }
        }
    
        .socure-c-sponsorbank-feedback-comments {
            label {
                font-size: 14px;
                font-weight: 700;
                line-height: 22px;
                display: block;
                margin-top: 10px;
            }
            span {
                font-size: 14px;
                font-weight: 700;
                line-height: 22px;
                text-transform: capitalize;
            }
        }
        .socure-c-request-feedback-close {
            position: absolute;
            right: 5px;
            top: 5px;
        }
    }
}

.socure-c-datagrid-row {
    div[id$='useCaseMapping'] {
        overflow: visible;
        > div {
            width: 100%;
            overflow: visible;

            .socure-c-decision-list-event-dropdown {
                position: relative;
                > div button {
                    height: 35px;

                    label {
                        top: 5px;
                        background: transparent;
                    }
                }
                ul {
                    position: absolute;
                    z-index: 500;
                    border: 1px solid var(--socure-global--color-info-pill-outline-border);
                    border-radius: 4px;
                    margin-top: 3px;
                    min-width: 150px;
                    box-shadow: 0 0 5px rgba(0, 0, 0, .3);
                    background: #fff;

                    li {
                        font-size: 13px;
                        padding: 5px 10px;
                        text-transform: capitalize;
                    }
                }
            }
            .socure-c-dropdownitem {
                cursor: default;

                .socure-c-text-overflow {
                    text-transform: capitalize;
                }
            }
        }
    }
}


.socure-c-request-outcome-collapse-container .socure-c-request-change-accordian {
    .socure-c-collapsible-button-space {
        border-radius: 4px;
        background: #FFF;
        box-shadow: 1px 0px 5px 0px rgba(0, 0, 0, 0.13);
        padding: 15px 10px 15px 34px;
        margin-bottom: 15px;
        border-left: 4px solid #237791;
        transition: .2s linear;

        &[aria-expanded="true"] {
            border-left: 12px solid #237791;
        }

        span.socure-c-request-change-outcome {
            color: #151515;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 18px;
            width: 150px;
            text-align: left;
            text-transform: capitalize;
        }
        .socure-c-request-status-count {
            margin-left: 80px;

            > span {
                margin-left: 20px;
                color: #26272C;
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                cursor: default;

                svg {
                    margin-right: 4px;
                    position: relative;
                    top: 1px;
                }
            }
            .socure-c-request-rejected-count {
                > svg {
                    top: 3px;
                }
            }
        }
    }
    .socure-c-collapsible-content {
        .socure-c-request-change-details {
            border-radius: 8px;
            border: 1px solid var(--grey-palette-grey-10, #F0EFEF);
            padding: 8px 8px 20px 8px;
            margin-bottom: 15px;
            cursor: pointer;
            transition: .2s linear;

            .socure-c-request-condition-details {
                float: left;

                h4{
                    color: #26272C;
                    font-size: 13px;
                    font-weight: 600;
                    line-height: 16px; 
                    margin-bottom: 2px;
                }
                span {
                    color: #54565A;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 14px;
                }
            }
            .socure-c-request-condition-action {
                float: right;
                width: 80px;
                display: flex;
                justify-content: space-around;
                margin-top: 6px;
                cursor: pointer;

                > div {
                    display: flex;
                    padding: 7px;
                    justify-content: center;
                    align-items: center;
                    border-radius: 4px;
                    border: 1px solid #237791;

                    &.active {
                        background: var(--Hero-Teal, #237791);

                        svg {
                            fill: #fff;
                        }
                    }
                }
            }
            &:after {
                clear: both;
                content: '';
                display: block;
            }
            &:hover {
                border: 1px solid #237791;
                box-shadow: 1px 0px 5px 0px rgba(0, 0, 0, 0.13);
            }
        }
    }
}