.socure-c-progress-bar_container {
    width: 100%;
    background-color: #eee;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
}

.socure-c-progress-bar {
    height: 20px;
    background-color: #ff6900;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
}