.socure-c-decision-table-container {
  display: flex;
  flex-direction: column;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.socure-c-decision-table-container .socure-c-decision-list-table {
  width: 100%;
  transition: .2s linear;
  display: flex;
  flex-direction: column;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.socure-c-decision-table-container .socure-c-audit-trail {
  position: absolute;
  top: 0px;
  right: 0;
  background: #FDFDFD;
  border-left: 1px solid #DADCE0;
  border-right: 1px solid #DADCE0;
  transition: .2s linear;
}

.socure-c-decision-table-container .socure-c-audit-trail .socure-c-audit-header {
  position: relative;
  padding: 13px 20px;
  background: #2B3139;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #FFFFFF;
}

.socure-c-decision-table-container .socure-c-audit-trail .socure-c-audit-header span.socure-c-audit-close {
  position: absolute;
  top: 12px;
  right: 10px;
  cursor: pointer;
}

.socure-c-decision-table-container .socure-c-audit-trail .socure-c-audit-body {
  height: calc(100% - 43px);
  position: relative;
}

.socure-c-decision-table-container .socure-c-audit-trail .socure-c-audit-body .socure-c-audit-filter {
  display: flex;
  padding: 12px;
  justify-content: space-between;
}

.socure-c-decision-table-container .socure-c-audit-trail .socure-c-audit-body .socure-c-audit-filter>.socure-c-audit-filter-icon {
  position: relative;
  top: 6px;
  cursor: pointer;
}

.socure-c-decision-table-container .socure-c-audit-trail .socure-c-audit-body .socure-c-audit-filter>.socure-c-audit-filter-clear {
  cursor: pointer;
}

.socure-c-decision-table-container .socure-c-audit-trail .socure-c-audit-body .socure-c-audit-data {
  padding: 0 12px 8px;
}

.socure-c-decision-table-container .socure-c-audit-trail .socure-c-audit-body .socure-c-audit-data h3 {
  font-weight: 700;
  font-size: 13px;
  display: inline-block;
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.socure-c-decision-table-container .socure-c-audit-trail .socure-c-audit-body .socure-c-audit-data span {
  float: right;
  font-weight: 700;
  font-size: 13px;
}

.socure-c-decision-table-container .socure-c-audit-trail .socure-c-audit-body .socure-c-audit-list-container {
  padding: 0 12px;
  height: calc(100% - 56px);
  overflow: auto;
}

.socure-c-decision-table-container .socure-c-audit-trail .socure-c-audit-body .socure-c-audit-list-container .socure-c-timeline-item__container {
  padding: 12px 0 15px;
  border-bottom: 1px solid #E1E0DF;
  width: 100%;
}

.socure-c-decision-table-container .socure-c-audit-trail .socure-c-audit-body .socure-c-audit-list-container .socure-c-timeline-item__container .socure-c-timeline-item {
  padding-left: 10px;
}

.socure-c-decision-table-container .socure-c-audit-trail .socure-c-audit-body .socure-c-audit-list-container .socure-c-timeline-item__container .socure-c-timeline-item .socure-c-timeline-item__h2 {
  font-weight: 800;
  font-size: 12px;
  line-height: 18px;
}

.socure-c-decision-table-container .socure-c-audit-trail .socure-c-audit-body .socure-c-audit-list-container .socure-c-timeline-item__container .socure-c-timeline-item .socure-c-audit-details {
  margin-top: 10px;
}

.socure-c-decision-table-container .socure-c-audit-trail .socure-c-audit-body .socure-c-audit-list-container .socure-c-timeline-item__container .socure-c-timeline-item .socure-c-audit-details span {
  font-size: 14px;
  line-height: 24px;
}

.socure-c-decision-table-container .socure-c-audit-trail .socure-c-audit-body .socure-c-audit-list-container .socure-c-timeline-item__container .socure-c-timeline-item .socure-c-audit-details .socure-c-audit-reason {
  font-size: 14px;
  line-height: 24px;
}

.socure-c-decision-table-container .socure-c-audit-trail .socure-c-audit-body .socure-c-audit-list-container .socure-c-timeline-item__container .socure-c-timeline-item .socure-c-audit-details .socure-c-audit-comment {
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  max-height: 75px;
  overflow: auto;
}

.socure-c-decision-table-container .socure-c-audit-trail .socure-c-audit-body .socure-c-audit-list-container .socure-c-timeline-item__container:last-child {
  border: none;
}

.socure-c-decision-table-container .socure-c-audit-trail .socure-c-audit-body .socure-c-audit-list-container span.socure-c-audit-action {
  position: absolute;
  top: -2px;
  right: 0;
}

.socure-c-decision-table-container .socure-c-audit-trail .socure-c-audit-body .socure-c-audit-list-container .socure-c-audit-empty-list {
  margin: 80px auto;
  text-align: center;
  font-size: 20px;
  opacity: .6;
}

.socure-c-decision-table-container .socure-c-audit-trail .socure-c-audit-body .socure-c-audit-avatar {
  display: flex;
}

.socure-c-decision-table-container .socure-c-audit-trail .socure-c-audit-body .socure-c-audit-avatar span {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin-left: 7px;
  width: 80%;
  overflow-y: auto;
}

.socure-c-decision-table-container .socure-c-audit-trail .socure-c-audit-body .socure-c-audit-avatar span::-webkit-scrollbar {
  display: none;
}

.socure-c-decision-table-container .socure-c-audit-trail .socure-c-audit-body .socure-audit-loader {
  position: absolute;
  left: 48%;
  top: 100px;
}

.socure-c-decision-table-container .socure-c-audit-trail .socure-c-audit-label {
  font-weight: 600;
  font-size: 12px;
  position: relative;
  bottom: -5px;
  text-transform: uppercase;
  color: #858585;
}

.socure-c-decision-table-container.socure-c-decision-table-audit {
  position: relative;
}

.socure-c-decision-table-container.socure-c-decision-table-audit .socure-c-decision-list-table {
  width: calc(100% - 300px);
}

.socure-c-decision-table-container.socure-c-decision-table-audit .socure-c-audit-trail {
  width: 330px;
  height: 100%;
}

.socure-c-decision-table-container .socure-c-audit-filter-container {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  background: #fff;
  padding: 16px;
}

.socure-c-decision-table-container .socure-c-audit-filter-container>div {
  margin-bottom: 26px;
}

.socure-c-decision-table-container .socure-c-audit-filter-container>div:last-child {
  margin-top: 60px;
}

.socure-c-decision-table-container .socure-c-audit-filter-container>div.socure-c-audit-filter-action {
  float: right;
}

.socure-c-decision-table-container .socure-c-audit-filter-container>div.socure-c-audit-filter-action button {
  margin-left: 16px;
}

.socure-c-decision-audit-list {
  width: 100%;
}

