body {
  color: var(--socure-global--color-black);
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  margin: 0;
  outline: none;
}

.application-root {
  display: flex;
  flex-direction: column;
}

.logo-container {
  display: flex;
}

.socure-c-toast-container {
  .Toastify__toast {
    //stylelint-disable-line
    padding: 0 !important;
  }
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.w-75 {
  width: 75%;
}

.w-30 {
  width: 30%;
}

.min-w-0 {
  min-width: 0;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.input-icon-start {
  left: 18px;
  top: 20px;
}

.input-icon-end {
  right: 18px;
  top: 20px;
}

.p-auto {
  padding: auto;
}

.m-auto {
  margin: auto;
}

.flex {
  display: flex !important;
}

.flex0 {
  flex: 0 0 auto !important;
}

.flex1 {
  flex: 1 1 auto !important;
}

.flex50 {
  flex: 1 1 50% !important;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-left {
  justify-content: left;
}

.justify-center {
  justify-content: center !important;
}

.justify-right {
  justify-content: right;
}

.justify-end {
  justify-content: flex-end;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-space-around {
  justify-content: space-around;
}

.align-left {
  align-items: flex-end;
}

.align-center {
  align-items: center;
}

.align-right {
  align-items: flex-end;
}

.mt-025 {
  margin-top: 4px !important;
}

.mb-025 {
  margin-bottom: 4px !important;
}

.mt-05 {
  margin-top: 8px !important;
}

.mb-05 {
  margin-bottom: 8px !important;
}

.mr-05 {
  margin-right: 8px !important;
}

.ml-05 {
  margin-left: 8px !important;
}

.ml-025 {
  margin-left: 4px !important;
}

.ml-075 {
  margin-left: 12px !important;
}

.mb-075 {
  margin-bottom: 12px !important;
}


.mt-1 {
  margin-top: 16px !important;
}

.mb-1 {
  margin-bottom: 16px !important;
}

.mr-025 {
  margin-right: 4px !important;
}

.mr-075 {
  margin-right: 12px !important;
}

.mr-1 {
  margin-right: 16px !important;
}

.ml-1 {
  margin-left: 16px !important;
}

.mb-150 {
  margin-bottom: 24px !important;
}

.pb-150 {
  padding-bottom: 24px !important;
}

.mt-150 {
  margin-top: 24px !important;
}

.mr-150 {
  margin-right: 24px !important;
}

.ml-150 {
  margin-left: 24px !important;
}

.mt-2 {
  margin-top: 32px !important;
}

.mb-2 {
  margin-bottom: 32px !important;
}

.mr-2 {
  margin-right: 32px !important;
}

.ml-2 {
  margin-left: 32px !important;
}

.ma-05 {
  margin: 8px !important;
}

.ma-1 {
  margin: 16px !important;
}

.ma-2 {
  margin: 32px !important;
}

.ml-3 {
  margin-left: 48px !important;
}

.mr-3 {
  margin-right: 48px !important;
}

.mt-3 {
  margin-top: 48px !important;
}

.mb-3 {
  margin-bottom: 48px !important;
}

.ma-3 {
  margin: 48px !important;
}

.mx-05 {
  margin: 0 8px !important;
}

.pt-05 {
  padding-top: 8px !important;
}

.pb-05 {
  padding-bottom: 8px !important;
}

.pr-05 {
  padding-right: 8px !important;
}

.pl-05 {
  padding-left: 8px !important;
}

.pt-1 {
  padding-top: 16px !important;
}

.pb-1 {
  padding-bottom: 16px !important;
}

.pr-1 {
  padding-right: 16px !important;
}

.pl-1 {
  padding-left: 16px !important;
}

.pl-150 {
  padding-left: 24px !important;
}

.pr-150 {
  padding-right: 24px !important;
}

.pt-2 {
  padding-top: 32px !important;
}

.pb-2 {
  padding-bottom: 32px !important;
}

.pr-2 {
  padding-right: 32px !important;
}

.pl-2 {
  padding-left: 32px !important;
}

.pl-3 {
  padding-left: 48px !important;
}

.pa-1 {
  padding: 16px !important;
}

.pa-2 {
  padding: 32px !important;
}

.px-1 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.py-05 {
  padding-bottom: 8px !important;
  padding-top: 8px !important;
}

.height-100vh {
  height: 100vh;
}

.height-auto {
  height: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.bg-transparent {
  background-color: transparent;
}

.hide {
  display: none;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.border-bottom {
  border-bottom: 1px solid var(--socure-global--color-popver-footer);
}

.border-left {
  border-left: 1px solid var(--socure-global--color-popver-footer);
}

.expend-collaps {
  color: #247bfc;
  font: var(--socure-global--FontSize--xs) / var(--socure-global--spacer--m)
    var(--socure-global-font-family);
  font-style: normal;
  letter-spacing: none;
  margin-right: 16px;
  opacity: 1;
  text-align: right;
  text-decoration: underline;

  button {
    margin: 0;
    padding: 0;
  
    &:focus-visible {
      border: 2px solid var(--socure-global--button-focus-border-color);
      padding: 2px;
      margin-bottom: 4px;
      border-radius: 4px;
    }
  }
}

.font-bold {
  font-weight: var(--socure-global--FontWeight--bold);
}

.font-semi-bold {
  font-weight: var(--socure-global--FontWeight--semi-bold);
}

.text-xs {
  font-size: var(--socure-global--FontSize--xs);
}

.text-camel-case {
  text-transform: capitalize;
}

.text-upper-case {
  text-transform: uppercase;
}

.text-lower-case {
  text-transform: lowercase;
}

.white-space-nowrap {
  white-space: nowrap;
}

@mixin padding-left($val: 0px) {
  padding-left: $val;
}

.padding-left-0 {
  @include padding-left(0px);
}

.padding-left-5 {
  @include padding-left(5px);
}

.padding-left-10 {
  @include padding-left(10px);
}

.padding-left-15 {
  @include padding-left(15px);
}

.padding-left-20 {
  @include padding-left(20px);
}

.padding-left-25 {
  @include padding-left(25px);
}

.padding-left-30 {
  @include padding-left(30px);
}

.padding-left-35 {
  @include padding-left(35px);
}

.padding-left-40 {
  @include padding-left(40px);
}

.padding-left-45 {
  @include padding-left(45px);
}

.padding-left-50 {
  @include padding-left(50px);
}