.socure-c-simulate-date-container {
  display: flex;
  align-items: center;
  margin: 0 25px;
  padding: 10px 0 20px 0;
  border-bottom: 1px solid #B2B2B2;
}

.socure-c-simulate-date-container .socure-c-simulate-date-selector {
  width: 150px;
  display: inline-block;
  position: relative;
  top: 7px;
}

.socure-c-simulate-date-container .socure-c-simulate-date-selector #dateSelector {
  height: 32px;
}

.socure-c-simulate-date-container>div .socure-c-date-selector-label {
  font-size: var(--socure-global--FontSize--xs);
  line-height: var(--socure-global--LineHeight-24);
  color: var(--socure-global--color-gray-20);
  position: relative;
  top: -3px;
}

.socure-c-simulate-date-container>div .socure-c-date-selector-label+div {
  margin-top: 0;
  height: c;
}

.socure-c-simulate-date-container>div .socure-c-textInput-OutterContainer {
  display: block;
}

.socure-c-simulate-date-container>div .socure-c-textInput-OutterContainer>div {
  top: 2px;
}

.socure-c-simulate-date-container>div .socure-c-textInput-OutterContainer input {
  margin: 0;
  height: 32px;
}

.socure-c-simulate-date-container #socure-c-simulate-run-btn {
  margin-left: 25px;
  border-radius: 4px;
  margin-top: 28px;
  height: 32px;
}

.socure-c-simulate-date-container>div {
  margin-right: 25px;
}

.socure-c-simulate-date-container>div.socure-c-simulate-comment,
.socure-c-simulate-date-container>div.socure-c-simulate-name,
.socure-c-simulate-date-container>div.socure-c-liveLogic-dropdown {
  width: 300px;
}

.socure-c-simulate-date-container>div.socure-c-simulate-comment>div,
.socure-c-simulate-date-container>div.socure-c-simulate-name>div,
.socure-c-simulate-date-container>div.socure-c-liveLogic-dropdown>div {
  margin: 0;
  height: 32px;
}

.socure-c-simulate-date-container>div.socure-c-simulate-comment>div button,
.socure-c-simulate-date-container>div.socure-c-simulate-name>div button,
.socure-c-simulate-date-container>div.socure-c-liveLogic-dropdown>div button {
  height: 32px;
}

.socure-c-simulation-result-container {
  margin: 0 25px;
}

.socure-c-simulation-result-container .socure-c-simulation-result-header {
  padding: 15px 0;
}

.socure-c-simulation-result-container .socure-c-simulation-result-header .socure-c-simulation-result-action {
  position: relative;
  top: -2px;
  float: right;
}

.socure-c-simulation-result-container .socure-c-simulation-result-header .socure-c-simulation-result-action button {
  margin-left: 16px;
}

.socure-c-simulation-result-container .socure-c-simulation-result-header span {
  font-size: 20px;
  line-height: 28px;
  color: #2B3139;
}

.socure-c-simulation-result-container .socure-c-simulation-result-body {
  border-radius: 6px;
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.socure-c-simulation-result-container .socure-c-simulation-result-body .socure-c-simulation-result-table {
  transition: .1s linear;
  width: 100%;
}

.socure-c-simulation-result-container .socure-c-simulation-result-body .socure-c-simulation-result-table .socure-c-simulation-result-table-header {
  margin-bottom: 16px;
}

.socure-c-simulation-result-container .socure-c-simulation-result-body .socure-c-simulation-result-table .socure-c-simulation-result-table-header:after {
  content: "";
  display: block;
  clear: both;
}

.socure-c-simulation-result-container .socure-c-simulation-result-body .socure-c-simulation-result-table .socure-c-simulation-result-table-header .socure-c-simulation-result-optoin {
  float: right;
  width: 325px;
}

.socure-c-simulation-result-container .socure-c-simulation-result-body .socure-c-simulation-result-table .socure-c-simulation-result-table-header .socure-c-simulation-result-optoin>div {
  margin-top: 0;
  height: 32px;
}

.socure-c-simulation-result-container .socure-c-simulation-result-body .socure-c-simulation-result-table .socure-c-simulation-result-table-header .socure-c-simulation-result-optoin>div>button {
  height: 32px;
}

.socure-c-simulation-result-container .socure-c-simulation-result-body .socure-c-simulation-result-table .socure-c-simulation-result-table-header .socure-c-simulation-result-optoin>div>button .socure-c-dropdown__placeholder-label--empty {
  top: 4px;
}

.socure-c-simulation-result-container .socure-c-simulation-result-body .socure-c-simulation-result-table .socure-c-simulation-result-table-header #socure-c-simulation-result-view-all {
  float: right;
  background: var(--socure-global--color-white);
  color: var(--socure-global--color-decision-condition-button);
  cursor: pointer;
}

.socure-c-simulation-result-container .socure-c-simulation-result-body .socure-c-simulation-result-table .socure-c-simulation-name-status {
  color: var(--socure-global--color-red-60);
  margin-left: 30px;
}

.socure-c-simulation-result-container .socure-c-simulation-result-body .socure-c-selected-simulation {
  width: 45%;
  margin-left: 32px;
  padding: 0 16px 0 25px;
  border-left: 1px solid #E8E8E8;
  position: absolute;
  right: 0px;
  top: 0;
  background: #fff;
}

.socure-c-simulation-result-container .socure-c-simulation-result-body .socure-c-selected-simulation>div,
.socure-c-simulation-result-container .socure-c-simulation-result-body .socure-c-selected-simulation>ul {
  padding: 12px 0;
  border-bottom: 1px solid #E1E0DF;
}

.socure-c-simulation-result-container .socure-c-simulation-result-body .socure-c-selected-simulation>div .socure-c-simulation-name-field,
.socure-c-simulation-result-container .socure-c-simulation-result-body .socure-c-selected-simulation>ul .socure-c-simulation-name-field {
  margin-bottom: 12px;
}

.socure-c-simulation-result-container .socure-c-simulation-result-body .socure-c-selected-simulation>div svg,
.socure-c-simulation-result-container .socure-c-simulation-result-body .socure-c-selected-simulation>ul svg {
  position: relative;
  top: 4px;
}

.socure-c-simulation-result-container .socure-c-simulation-result-body .socure-c-selected-simulation .socure-c-selected-simulation-name>svg {
  position: relative;
  top: 4px;
  left: -2px;
}

.socure-c-simulation-result-container .socure-c-simulation-result-body .socure-c-selected-simulation .socure-c-selected-simulation-name span.socure-c-simulation-name {
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  margin: 0 16px;
  color: #858585;
}

.socure-c-simulation-result-container .socure-c-simulation-result-body .socure-c-selected-simulation .socure-c-selected-simulation-name .socure-c-simulation-comment {
  margin: 0 16px;
  font-weight: 400;
  font-size: 12px;
  color: #26272C;
}

.socure-c-simulation-result-container .socure-c-simulation-result-body .socure-c-selected-simulation .socure-c-selected-simulation-name span.socure-c-simulation-date {
  font-weight: 400;
  font-size: 14px;
  color: #858585;
}

.socure-c-simulation-result-container .socure-c-simulation-result-body .socure-c-selected-simulation ul.socure-c-selected-simulation-details {
  display: flex;
  justify-content: space-between;
}

.socure-c-simulation-result-container .socure-c-simulation-result-body .socure-c-selected-simulation ul.socure-c-selected-simulation-details li {
  text-align: center;
}

.socure-c-simulation-result-container .socure-c-simulation-result-body .socure-c-selected-simulation ul.socure-c-selected-simulation-details label {
  display: block;
  font-weight: 700;
  font-size: 10px;
  line-height: 19px;
  text-transform: uppercase;
  color: #858585;
}

.socure-c-simulation-result-container .socure-c-simulation-result-body .socure-c-selected-simulation ul.socure-c-selected-simulation-details span {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #26272C;
}

.socure-c-simulation-result-container .socure-c-simulation-result-body .socure-c-selected-simulation .socure-c-simulation-text::after {
  display: block;
  content: '';
  clear: both;
}

.socure-c-simulation-result-container .socure-c-simulation-result-body .socure-c-selected-simulation .socure-c-simulation-text>div {
  float: left;
  width: 460px;
}

.socure-c-simulation-result-container .socure-c-simulation-result-body .socure-c-selected-simulation .socure-c-simulation-text>div span {
  color: #858585;
  margin-bottom: 4px;
  display: inline-block;
  font-weight: 700;
  font-size: 12px;
}

.socure-c-simulation-result-container .socure-c-simulation-result-body .socure-c-selected-simulation .socure-c-simulation-text>div p {
  color: #54565A;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
}

.socure-c-simulation-result-container .socure-c-simulation-result-body .socure-c-selected-simulation .socure-c-simulation-table .header-cell {
  font-weight: 700;
  font-size: 10px;
  color: #858585;
  text-align: center;
}

.socure-c-simulation-result-container .socure-c-simulation-result-body .socure-c-selected-simulation .socure-c-simulation-table .socure-c-datagrid-row .row-entry {
  height: 22px;
  border-bottom: 1px solid #F0EFEF;
}

.socure-c-simulation-result-container .socure-c-simulation-result-body .socure-c-selected-simulation .socure-c-simulation-table .socure-c-datagrid-row .row-entry .data-cell {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #26272C;
}

.socure-c-simulation-result-container .socure-c-simulation-result-body .socure-c-selected-simulation .socure-c-simulation-table .socure-c-datagrid-row:last-child .row-entry {
  border: none;
}

.socure-c-simulation-result-container .socure-c-simulation-result-body .socure-c-selected-simulation .socure-c-simulation-table .socure-c-datagrid-container>div:first-child {
  height: 22px;
  min-height: 22px;
}

.socure-c-simulation-result-container .socure-c-simulation-result-body .socure-c-selected-simulation .socure-c-simulation-text.socure-c-simulation-acceptance-rate {
  position: relative;
}

.socure-c-simulation-result-container .socure-c-simulation-result-body .socure-c-selected-simulation .socure-c-simulation-text.socure-c-simulation-acceptance-rate .socure-c-guageChart-container {
  position: absolute;
  top: -12px;
  right: -40px;
}

.socure-c-simulation-result-container .socure-c-simulation-result-body .socure-c-selected-simulation .socure-c-simulation-text.socure-c-simulation-acceptance-rate .socure-c-guageChart-container canvas {
  width: 90px !important;
  height: 90px !important;
}

.socure-c-simulation-result-container .socure-c-simulation-result-body .socure-c-selected-simulation .socure-c-simulation-text.socure-c-simulation-acceptance-rate .socure-c-guageChart-container .socure-c-guageChart-details h3 {
  display: inline-block;
  position: relative;
  right: 28px;
  top: 7px;
}

.socure-c-simulation-result-container .socure-c-simulation-result-body.socure-c-simulation-data-visible .socure-c-simulation-result-table {
  width: 55%;
  padding-right: 15px;
}

.socure-c-simulation-result-container .socure-c-simulation-result-body .socure-c-datagrid-container .data-cell,
.socure-c-simulation-result-container .socure-c-simulation-result-body .socure-c-datagrid-container .header-cell {
  text-align: center;
}

.socure-c-simulation-result-container .socure-c-simulation-result-body .socure-c-datagrid-container .data-cell:first-child,
.socure-c-simulation-result-container .socure-c-simulation-result-body .socure-c-datagrid-container .header-cell:first-child {
  text-align: left;
}

.socure-c-simulation-result-container .socure-c-simulation-result-table-body .data-cell .socure-c-text-overflow {
  font-weight: 400;
  font-size: 14px;
  color: #1E2833;
}

.socure-c-simulation-result-container .socure-c-simulation-result-table-body .data-cell:first-child .socure-c-text-overflow {
  font-weight: 700;
  font-size: 13px;
  color: #0E1115;
}

.socure-c-simulation-result-container .simulation-status-loader {
  position: relative;
}

.socure-c-simulation-result-container .simulation-status-loader>div {
  display: inline-block;
  margin-left: 15px;
}

.socure-c-simulation-result-container .socure-c-transaction-alert-tooltip {
  margin-left: 25px;
  display: inline-block;
}

.socure-c-simulation-result-container .socure-c-transaction-alert-tooltip>div {
  width: 400px !important;
  padding: 4px 8px;
  background: #5B5F66;
}

.socure-c-simulation-result-container .socure-c-transaction-alert-tooltip .socure-c-transaction-alert-tooltip-content {
  border-radius: 4px;
  font-size: 13px;
}

.socure-c-modal-popup__container .socure-c-deploy-container>div:first-child,
.socure-c-modal-popup__container .socure-c-revert-container>div:first-child,
.socure-c-modal-popup__container .socure-c-event-mapping-container>div:first-child {
  margin: 18px 0 5px;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #1E2833;
  display: none;
}

.socure-c-modal-popup__container .socure-c-deploy-container>label,
.socure-c-modal-popup__container .socure-c-revert-container>label,
.socure-c-modal-popup__container .socure-c-event-mapping-container>label {
  font-weight: 700;
  font-size: 12px;
  display: inline-block;
  text-transform: uppercase;
  color: #858585;
  margin-bottom: 5px;
}

.socure-c-modal-popup__container .socure-c-deploy-container>label.socure-c-deploy-comment-label,
.socure-c-modal-popup__container .socure-c-revert-container>label.socure-c-deploy-comment-label,
.socure-c-modal-popup__container .socure-c-event-mapping-container>label.socure-c-deploy-comment-label {
  margin-top: 24px;
}

.socure-c-modal-popup__container .socure-c-deploy-container>label.socure-c-deploy-reason-label,
.socure-c-modal-popup__container .socure-c-revert-container>label.socure-c-deploy-reason-label,
.socure-c-modal-popup__container .socure-c-event-mapping-container>label.socure-c-deploy-reason-label {
  position: relative;
  bottom: -10px;
}

.socure-c-modal-popup__container .socure-c-deploy-container>label.socure-c-select-environment-label,
.socure-c-modal-popup__container .socure-c-revert-container>label.socure-c-select-environment-label,
.socure-c-modal-popup__container .socure-c-event-mapping-container>label.socure-c-select-environment-label {
  margin-bottom: 10px;
}

.socure-c-modal-popup__container .socure-c-deploy-container .socure-c-checkbox-container,
.socure-c-modal-popup__container .socure-c-revert-container .socure-c-checkbox-container,
.socure-c-modal-popup__container .socure-c-event-mapping-container .socure-c-checkbox-container {
  margin-top: 16px;
}

.socure-c-modal-popup__container .socure-c-deploy-container .socure-c-checkbox-container .socure-c-checkbox-label,
.socure-c-modal-popup__container .socure-c-revert-container .socure-c-checkbox-container .socure-c-checkbox-label,
.socure-c-modal-popup__container .socure-c-event-mapping-container .socure-c-checkbox-container .socure-c-checkbox-label {
  text-transform: capitalize;
}

.socure-c-modal-popup__container .socure-c-deploy-container .socure-c-deploy-event-dropdown,
.socure-c-modal-popup__container .socure-c-revert-container .socure-c-deploy-event-dropdown,
.socure-c-modal-popup__container .socure-c-event-mapping-container .socure-c-deploy-event-dropdown {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 16px;
}

.socure-c-modal-popup__container .socure-c-deploy-container .socure-c-deploy-event-dropdown>div,
.socure-c-modal-popup__container .socure-c-revert-container .socure-c-deploy-event-dropdown>div,
.socure-c-modal-popup__container .socure-c-event-mapping-container .socure-c-deploy-event-dropdown>div {
  width: 250px;
  margin-right: 30px;
  display: inline-block;
}

.socure-c-modal-popup__container .socure-c-deploy-container .socure-c-deploy-event-dropdown>span,
.socure-c-modal-popup__container .socure-c-revert-container .socure-c-deploy-event-dropdown>span,
.socure-c-modal-popup__container .socure-c-event-mapping-container .socure-c-deploy-event-dropdown>span {
  display: inline-block;
  cursor: pointer;
  position: relative;
  top: 18px;
  width: 32px;
  height: 32px;
  text-align: center;
  padding: 7px;
  background: #26272C;
  border-radius: 4px;
}

.socure-c-modal-popup__container .socure-c-deploy-container .socure-c-deploy-event-dropdown>span.event-add-disable,
.socure-c-modal-popup__container .socure-c-revert-container .socure-c-deploy-event-dropdown>span.event-add-disable,
.socure-c-modal-popup__container .socure-c-event-mapping-container .socure-c-deploy-event-dropdown>span.event-add-disable {
  cursor: not-allowed;
}

.socure-c-modal-popup__container .socure-c-deploy-container .socure-c-deploy-event-dropdown span,
.socure-c-modal-popup__container .socure-c-revert-container .socure-c-deploy-event-dropdown span,
.socure-c-modal-popup__container .socure-c-event-mapping-container .socure-c-deploy-event-dropdown span {
  text-transform: capitalize;
}

.socure-c-modal-popup__container .socure-c-deploy-container .socure-c-event-tags-container,
.socure-c-modal-popup__container .socure-c-revert-container .socure-c-event-tags-container,
.socure-c-modal-popup__container .socure-c-event-mapping-container .socure-c-event-tags-container {
  display: flex;
  margin: 16px 0 0 0;
  flex-wrap: wrap;
  max-height: 130px;
  overflow: auto;
}

.socure-c-modal-popup__container .socure-c-deploy-container .socure-c-event-tags-container .socure-c-event-tags,
.socure-c-modal-popup__container .socure-c-revert-container .socure-c-event-tags-container .socure-c-event-tags,
.socure-c-modal-popup__container .socure-c-event-mapping-container .socure-c-event-tags-container .socure-c-event-tags {
  display: flex;
  margin: 0 15px 15px 0;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 50px;
  border: 1px solid var(--Grey-Palette-Grey-20, #E1E0DF);
  background: var(--Grey-Palette-Grey-20, #E1E0DF);
}

.socure-c-modal-popup__container .socure-c-deploy-container .socure-c-event-tags-container .socure-c-event-tags span,
.socure-c-modal-popup__container .socure-c-revert-container .socure-c-event-tags-container .socure-c-event-tags span,
.socure-c-modal-popup__container .socure-c-event-mapping-container .socure-c-event-tags-container .socure-c-event-tags span {
  color: var(--Grayscale-Gray-100, #161616);
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
  text-transform: capitalize;
}

.socure-c-modal-popup__container .socure-c-deploy-container .socure-c-event-tags-container .socure-c-event-tags cite,
.socure-c-modal-popup__container .socure-c-revert-container .socure-c-event-tags-container .socure-c-event-tags cite,
.socure-c-modal-popup__container .socure-c-event-mapping-container .socure-c-event-tags-container .socure-c-event-tags cite {
  text-transform: capitalize;
}

.socure-c-modal-popup__container .socure-c-deploy-container .socure-c-event-tags-container .socure-c-event-tags .socure-c-event-tag-close,
.socure-c-modal-popup__container .socure-c-revert-container .socure-c-event-tags-container .socure-c-event-tags .socure-c-event-tag-close,
.socure-c-modal-popup__container .socure-c-event-mapping-container .socure-c-event-tags-container .socure-c-event-tags .socure-c-event-tag-close {
  cursor: pointer;
  position: relative;
  top: 3px;
}

.socure-c-modal-popup__container .socure-c-deploy-container .socure-c-event-tags-container .socure-c-event-tags .socure-c-event-mapping-tooltip-content,
.socure-c-modal-popup__container .socure-c-revert-container .socure-c-event-tags-container .socure-c-event-tags .socure-c-event-mapping-tooltip-content,
.socure-c-modal-popup__container .socure-c-event-mapping-container .socure-c-event-tags-container .socure-c-event-tags .socure-c-event-mapping-tooltip-content {
  border-radius: 4px;
  font-size: 12px;
  color: #fff;
}

.socure-c-modal-popup__container .socure-c-deploy-container .socure-c-event-tags-container .socure-c-event-tags.socure-c-event-exist,
.socure-c-modal-popup__container .socure-c-revert-container .socure-c-event-tags-container .socure-c-event-tags.socure-c-event-exist,
.socure-c-modal-popup__container .socure-c-event-mapping-container .socure-c-event-tags-container .socure-c-event-tags.socure-c-event-exist {
  border-color: red;
}

.socure-c-modal-popup__container .socure-c-deploy-container .socure-c-event-tags-container .socure-c-event-tags.socure-c-event-exist>div,
.socure-c-modal-popup__container .socure-c-revert-container .socure-c-event-tags-container .socure-c-event-tags.socure-c-event-exist>div,
.socure-c-modal-popup__container .socure-c-event-mapping-container .socure-c-event-tags-container .socure-c-event-tags.socure-c-event-exist>div {
  width: 150px !important;
  padding: 4px 8px;
  background: #5B5F66;
}

.socure-c-modal-popup__container .socure-c-deploy-container .socure-c-event-tags-container .socure-c-event-tags.socure-c-event-exist>span,
.socure-c-modal-popup__container .socure-c-revert-container .socure-c-event-tags-container .socure-c-event-tags.socure-c-event-exist>span,
.socure-c-modal-popup__container .socure-c-event-mapping-container .socure-c-event-tags-container .socure-c-event-tags.socure-c-event-exist>span {
  display: flex;
  align-items: center;
  gap: 8px;
}

.socure-c-modal-popup__container .socure-c-deploy-container .socure-c-textArea-OutterContainer,
.socure-c-modal-popup__container .socure-c-revert-container .socure-c-textArea-OutterContainer,
.socure-c-modal-popup__container .socure-c-event-mapping-container .socure-c-textArea-OutterContainer {
  margin-bottom: 20px;
}

.socure-c-modal-popup__container .socure-c-revert-container>div:first-child,
.socure-c-modal-popup__container .socure-c-event-update-container>div:first-child {
  margin: 0 0 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
}

.socure-c-modal-popup__container .socure-c-revert-container textarea,
.socure-c-modal-popup__container .socure-c-event-update-container textarea {
  width: 420px;
}

.socure-c-modal-popup__container .socure-c-revert-container .socure-c-previous-live-details label,
.socure-c-modal-popup__container .socure-c-event-update-container .socure-c-previous-live-details label {
  font-weight: 600;
  font-size: 14px;
}

.socure-c-modal-popup__container .socure-c-revert-container .socure-c-previous-live-details span,
.socure-c-modal-popup__container .socure-c-event-update-container .socure-c-previous-live-details span {
  font-weight: 400;
  font-size: 14px;
  margin-right: 30px;
}

.socure-c-modal-popup__container .socure-c-revert-container .socure-c-textArea,
.socure-c-modal-popup__container .socure-c-event-update-container .socure-c-textArea {
  width: 100%;
}

.socure-c-modal-popup__container .socure-c-revert-container .socure-c-deploy-event-dropdown,
.socure-c-modal-popup__container .socure-c-event-update-container .socure-c-deploy-event-dropdown {
  margin-bottom: 0;
}

#decision-clone {
  visibility: hidden;
}

.socure-c-group-condition-container {
  width: 100%;
}

.socure-c-modal-popup__title-container .socure-c-decision-old-logic>div:nth-child(2n) {
  pointer-events: none;
  cursor: not-allowed !important;
}

.socure-c-lb-sub-group .socure-c-lb-condition-row .socure-c-menuItem__List #moveTo {
  display: none;
}

.socure-c-saveLogic-container {
  position: relative;
}

.socure-c-saveLogic-container span.socure-c-saveLogic-asteric {
  position: absolute;
  top: 31px;
  left: 76px;
  color: var(--socure-global--color-text-input-error);
  font-size: var(--socure-global--FontSize--2xs);
}

.socure-c-deploy-container {
  padding: 16px 0;
  display: flex;
  width: 600px;
}

.socure-c-deploy-container .socure-c-deploy-tab-content {
  width: 100%;
}

.socure-c-deploy-tab {
  width: 170px;
  border-right: 1px solid #CCCCCC;
  margin-right: 25px !important;
  margin-top: 0 !important;
  padding-top: 18px;
}

.socure-c-deploy-tab ol {
  list-style-type: none;
  padding-left: 25px;
  border-left: 1px solid #DDD;
}

.socure-c-deploy-tab ol li {
  margin-bottom: 33px;
  position: relative;
  counter-increment: step-counter;
  font-size: 15px;
  cursor: pointer;
}

.socure-c-deploy-tab ol li:before {
  color: #fff;
  content: counter(step-counter);
  position: absolute;
  right: 113%;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 100%;
  background: #00849E;
  text-align: center;
  line-height: 24px;
}

.socure-c-deploy-action {
  width: 100%;
}

.socure-c-deploy-action label {
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  background: transparent;
}

.socure-c-deploy-action>div {
  margin: 0 0 16px 0;
}

.socure-c-decision-request-container {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10;
  width: 450px;
  height: 100%;
  background: #FFF;
  box-shadow: -7px 0px 24px 0px rgba(14,17,21,0.1);
  padding-bottom: 70px;
  border-radius: 0 8px 8px 0;
}

.socure-c-decision-request-container .socure-c-decision-request-header {
  padding: 14px 12px;
  border-bottom: 1px solid #E3E4E6;
}

.socure-c-decision-request-container .socure-c-decision-request-header span {
  font-size: 22px;
  font-weight: 600;
  line-height: 24px;
  color: var(--Header-Text, #1E2833);
  padding-right: 10px;
}

.socure-c-decision-request-container .socure-c-decision-request-header .socure-c-request-header-action {
  float: right;
}

.socure-c-decision-request-container .socure-c-decision-request-header .socure-c-request-header-action>div {
  display: inline-block;
}

.socure-c-decision-request-container .socure-c-decision-request-header .socure-c-request-header-action svg {
  margin-right: 10px;
  cursor: pointer;
}

.socure-c-decision-request-container .socure-c-decision-request-body .socure-c-request-sub-accounts {
  padding: 20px;
}

.socure-c-decision-request-container .socure-c-decision-request-body .socure-c-request-sub-accounts>div {
  margin-bottom: 15px;
}

.socure-c-decision-request-container .socure-c-decision-request-body .socure-c-request-sub-accounts>div label {
  color: var(--grey-palette-grey-90, #26272C);
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  display: block;
}

.socure-c-decision-request-container .socure-c-decision-request-body .socure-c-request-sub-accounts>div span {
  color: var(--grey-palette-grey-90, #26272C);
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  display: inline-block;
}

.socure-c-decision-request-container .socure-c-decision-request-body {
  height: calc(100% - 70px);
  overflow: auto;
}

.socure-c-decision-request-container .socure-c-decision-request-body .socure-c-request-outcome-changes {
  padding: 12px 8px;
}

.socure-c-decision-request-container .socure-c-request-footer-action {
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 10px 16px;
  border-top: 1px solid #E3E4E6;
  background-color: #fff;
  border-radius: 0 8px 8px 0;
}

.socure-c-decision-request-container .socure-c-request-footer-action .socure-c-request-footer-submit {
  display: flex;
  justify-content: flex-end;
}

.socure-c-decision-request-container .socure-c-request-footer-action .socure-c-request-footer-submit>button {
  margin-left: 15px;
}

.socure-c-decision-request-container .socure-c-request-submit-container {
  padding: 16px 0;
}

.socure-c-decision-request-container .socure-c-request-submit-container p {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: var(--grey-palette-black, #000);
}

.socure-c-decision-request-container .socure-c-request-submit-container p span {
  font-weight: 700;
}

.socure-c-decision-request-container .socure-c-request-submit-container label {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 15px;
  display: inline-block;
  color: #858585;
}

.socure-c-decision-request-container .socure-c-request-submit-container textarea {
  margin-top: 0;
  height: 70px;
}

.socure-c-decision-request-container .socure-c-modal-popup__container {
  min-width: 440px;
}

.socure-c-sponsorbank-feedback-container {
  position: relative;
  cursor: pointer;
  top: 20px;
}

.socure-c-sponsorbank-feedback-container .socure-c-sponsorbank-feedback-result {
  position: absolute;
  top: 100%;
  width: 470px;
  padding: 20px 0 30px 16px;
  border-radius: 5px;
  z-index: 12;
  border: 1px solid #CDCDCD;
  background: #FFF;
  box-shadow: 0px 12px 12px 0px rgba(0,0,0,0.25);
}

.socure-c-sponsorbank-feedback-container .socure-c-sponsorbank-feedback-result p {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.socure-c-sponsorbank-feedback-container .socure-c-sponsorbank-feedback-result p span {
  font-weight: 700;
}

.socure-c-sponsorbank-feedback-container .socure-c-sponsorbank-feedback-result .socure-c-request-outcome-collapse-container {
  padding: 12px 16px 0 0;
  max-height: 310px;
  overflow: auto;
}

.socure-c-sponsorbank-feedback-container .socure-c-sponsorbank-feedback-result .socure-c-request-outcome-collapse-container .socure-c-request-status-count {
  margin-left: 60px;
}

.socure-c-sponsorbank-feedback-container .socure-c-sponsorbank-feedback-result .socure-c-request-outcome-collapse-container .socure-c-request-condition-action {
  cursor: default !important;
}

.socure-c-sponsorbank-feedback-container .socure-c-sponsorbank-feedback-result .socure-c-sponsorbank-feedback-comments label {
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  display: block;
  margin-top: 10px;
}

.socure-c-sponsorbank-feedback-container .socure-c-sponsorbank-feedback-result .socure-c-sponsorbank-feedback-comments span {
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  text-transform: capitalize;
}

.socure-c-sponsorbank-feedback-container .socure-c-sponsorbank-feedback-result .socure-c-request-feedback-close {
  position: absolute;
  right: 5px;
  top: 5px;
}

.socure-c-datagrid-row div[id$='useCaseMapping'] {
  overflow: visible;
}

.socure-c-datagrid-row div[id$='useCaseMapping']>div {
  width: 100%;
  overflow: visible;
}

.socure-c-datagrid-row div[id$='useCaseMapping']>div .socure-c-decision-list-event-dropdown {
  position: relative;
}

.socure-c-datagrid-row div[id$='useCaseMapping']>div .socure-c-decision-list-event-dropdown>div button {
  height: 35px;
}

.socure-c-datagrid-row div[id$='useCaseMapping']>div .socure-c-decision-list-event-dropdown>div button label {
  top: 5px;
  background: transparent;
}

.socure-c-datagrid-row div[id$='useCaseMapping']>div .socure-c-decision-list-event-dropdown ul {
  position: absolute;
  z-index: 500;
  border: 1px solid var(--socure-global--color-info-pill-outline-border);
  border-radius: 4px;
  margin-top: 3px;
  min-width: 150px;
  box-shadow: 0 0 5px rgba(0,0,0,0.3);
  background: #fff;
}

.socure-c-datagrid-row div[id$='useCaseMapping']>div .socure-c-decision-list-event-dropdown ul li {
  font-size: 13px;
  padding: 5px 10px;
  text-transform: capitalize;
}

.socure-c-datagrid-row div[id$='useCaseMapping']>div .socure-c-dropdownitem {
  cursor: default;
}

.socure-c-datagrid-row div[id$='useCaseMapping']>div .socure-c-dropdownitem .socure-c-text-overflow {
  text-transform: capitalize;
}

.socure-c-request-outcome-collapse-container .socure-c-request-change-accordian .socure-c-collapsible-button-space {
  border-radius: 4px;
  background: #FFF;
  box-shadow: 1px 0px 5px 0px rgba(0,0,0,0.13);
  padding: 15px 10px 15px 34px;
  margin-bottom: 15px;
  border-left: 4px solid #237791;
  transition: .2s linear;
}

.socure-c-request-outcome-collapse-container .socure-c-request-change-accordian .socure-c-collapsible-button-space[aria-expanded="true"] {
  border-left: 12px solid #237791;
}

.socure-c-request-outcome-collapse-container .socure-c-request-change-accordian .socure-c-collapsible-button-space span.socure-c-request-change-outcome {
  color: #151515;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  width: 150px;
  text-align: left;
  text-transform: capitalize;
}

.socure-c-request-outcome-collapse-container .socure-c-request-change-accordian .socure-c-collapsible-button-space .socure-c-request-status-count {
  margin-left: 80px;
}

.socure-c-request-outcome-collapse-container .socure-c-request-change-accordian .socure-c-collapsible-button-space .socure-c-request-status-count>span {
  margin-left: 20px;
  color: #26272C;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  cursor: default;
}

.socure-c-request-outcome-collapse-container .socure-c-request-change-accordian .socure-c-collapsible-button-space .socure-c-request-status-count>span svg {
  margin-right: 4px;
  position: relative;
  top: 1px;
}

.socure-c-request-outcome-collapse-container .socure-c-request-change-accordian .socure-c-collapsible-button-space .socure-c-request-status-count .socure-c-request-rejected-count>svg {
  top: 3px;
}

.socure-c-request-outcome-collapse-container .socure-c-request-change-accordian .socure-c-collapsible-content .socure-c-request-change-details {
  border-radius: 8px;
  border: 1px solid var(--grey-palette-grey-10, #F0EFEF);
  padding: 8px 8px 20px 8px;
  margin-bottom: 15px;
  cursor: pointer;
  transition: .2s linear;
}

.socure-c-request-outcome-collapse-container .socure-c-request-change-accordian .socure-c-collapsible-content .socure-c-request-change-details .socure-c-request-condition-details {
  float: left;
}

.socure-c-request-outcome-collapse-container .socure-c-request-change-accordian .socure-c-collapsible-content .socure-c-request-change-details .socure-c-request-condition-details h4 {
  color: #26272C;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  margin-bottom: 2px;
}

.socure-c-request-outcome-collapse-container .socure-c-request-change-accordian .socure-c-collapsible-content .socure-c-request-change-details .socure-c-request-condition-details span {
  color: #54565A;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
}

.socure-c-request-outcome-collapse-container .socure-c-request-change-accordian .socure-c-collapsible-content .socure-c-request-change-details .socure-c-request-condition-action {
  float: right;
  width: 80px;
  display: flex;
  justify-content: space-around;
  margin-top: 6px;
  cursor: pointer;
}

.socure-c-request-outcome-collapse-container .socure-c-request-change-accordian .socure-c-collapsible-content .socure-c-request-change-details .socure-c-request-condition-action>div {
  display: flex;
  padding: 7px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #237791;
}

.socure-c-request-outcome-collapse-container .socure-c-request-change-accordian .socure-c-collapsible-content .socure-c-request-change-details .socure-c-request-condition-action>div.active {
  background: var(--Hero-Teal, #237791);
}

.socure-c-request-outcome-collapse-container .socure-c-request-change-accordian .socure-c-collapsible-content .socure-c-request-change-details .socure-c-request-condition-action>div.active svg {
  fill: #fff;
}

.socure-c-request-outcome-collapse-container .socure-c-request-change-accordian .socure-c-collapsible-content .socure-c-request-change-details:after {
  clear: both;
  content: '';
  display: block;
}

.socure-c-request-outcome-collapse-container .socure-c-request-change-accordian .socure-c-collapsible-content .socure-c-request-change-details:hover {
  border: 1px solid #237791;
  box-shadow: 1px 0px 5px 0px rgba(0,0,0,0.13);
}

