.socure-app-description {
  padding: 0 16px;
  text-align: center;
}

.socure-v3-warning {
  align-items: center;
  display: flex;
  font-size: 12px;
  margin-left: 22px;
  line-height: normal;
}

.socure-v3-secondary-warning {
  font-size: 12px;
  font-weight: normal;
  margin-top: 10px;
}

.socure-doc-link {
  text-decoration: underline;
  color: #2B96B7;
}

.socure-static-message {
  text-align: center;
  font-weight: 600;
  margin: 21px 0 8px 0;
}

.socure-app-button {
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  height: 45px;
  padding: 32px 0 34px;
  padding: 0.8em 3em;
  text-align: center;
  width: 250px;
}

.socure-app-options-container {
  display: flex;
  justify-content: center;
  text-align: center;
}

.socure-app-page-container {
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  height: 100%;
  justify-content: center;
}

.option-divider-container {
  display: inline-flex;
  flex-direction: column;
  margin: 32px 0;
}

.option-divider-container .liner {
  align-self: center;
  border: 1px solid #c3c4c5;
  flex: 1 1 auto;
}

.option-container {
  margin: 32px 32px 34px;
  max-width: 400px;
  text-align: center;
}

input {
  border-radius: 5px;
  height: 35px;
  width: 100%;
}

.tab-content {
  cursor: pointer;
  padding: 10px 5px;
}

.error {
  color: var(--socure-global--color-red-40);
  font-size: var(--socure-global--FontSize--xs);
  font-style: normal;
  font-weight: normal;
  line-height: var(--socure-global--FontSize--m);
  text-align: left;
}

.socure-app-browser-topbar {
  background: rgba(195,196,197,0.25);
  border-radius: 10px;
  height: 24px;
  left: 42px;
  position: absolute;
  top: 60px;
  width: 260px;
}

.socure-app-browser-bottom {
  background: #f8f8f8;
  border-bottom: 10px;
  border-radius: 0 0 25px 25px;
  border-top: solid 1px var(--socure-global--color-content-footer-border);
  bottom: 31px;
  height: 54px;
  left: 36px;
  position: absolute;
  width: 278px;
}

.socure-app-browser-bottom-line {
  background: #c3c4c5;
  border-radius: 100px;
  bottom: 7px;
  height: 5px;
  left: 75px;
  position: absolute;
  width: 135px;
}

.socure-app-flows-disabled {
  opacity: 0.4;
  pointer-events: none;
}

.socure-app-topbar-label {
  color: var(--socure-global--color-blue-20);
  font-size: 10px;
}

.socure-app-topbar-logo {
  align-items: center;
  background: #fff;
  display: flex;
  justify-content: center;
  padding: 2%;
}

